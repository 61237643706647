<header class="sticky top-0 flex w-full bg-gray-50 dark:bg-gray-900">
  <nav class="flex w-full p-20 mx-auto item-center max-w-1920 gap-x-20">
    <a routerLink="/" class="flex items-center justify-start mr-80">
      <img src="./assets/logo/hatomi-logo.svg" alt="Hatomi Logo" class="w-36 h-36">
      <span class="ml-10 text-xs font-bold tracking-widest text-gray-600 uppercase dark:text-gray-50">Hatomi Design</span>
    </a>
    <a class="px-20 py-10 my-10 text-xs font-bold tracking-wider uppercase rounded-full" routerLinkActive="text-primary-pink-500" *ngFor="let item of navRoutes"
      [routerLink]="item.path">{{item.data?.title}}</a>
  </nav>
  <app-toggle-switch [on]="darkMode" [icon]="'mdi-lightbulb'" (toggled)="toggleDarkMode()" class="flex-shrink-0 mx-20 my-auto">
    Dark Mode
  </app-toggle-switch>
</header>
<section class="flex flex-col flex-grow flex-shrink-0 w-full mx-auto">
  <router-outlet></router-outlet>
</section>
<footer>

</footer>